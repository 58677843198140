/* 
     ___ _        _     
    / __| |_ _  _| |___ 
    \__ \  _| || | / -_)
    |___/\__|\_, |_\___|
             |__/       

    Table of contents:
    - General style
    - Input fields
        - Radio button group
        - Checkbox group
        - Date group
    - Container
    - Job application form
        - Process
        - View
    - Media queries
*/

/* General style */
    /* * { box-sizing: border-box } */
    /* @font-face { font-family: NettoOT; src: url(../fonts/NettoOt/NettoOT.otf) }
    @font-face { font-family: Lato; src: url(../fonts/Lato/Lato.ttf) }
    @font-face { font-family: LatoLight; src: url(../fonts/Lato/Lato-Light.ttf) }
    @font-face { font-family: LatoBold; src: url(../fonts/Lato/Lato-Bold.ttf) } */
    :root {

        /* Colors */
        --primaryColor: #EE2E24;
        --darkGreyColor: #939393;
        --lightGreyColor: #ededed;
        --textColor: #231f20;

        --error: #ee2e2e;
        
        /* Fonts */
        --primaryFont: 'NettoOT', sans-serif;
        --secundaryFont: 'Lato', sans-serif;
        --secundaryFontLight: 'LatoLight', sans-serif;
        --secundaryFontBold: 'LatoBold', sans-serif;
    }
    html { scroll-behavior: smooth }
    section#superplanner h1 {
        font-family: var(--primaryFont);
        font-size: 40px;
        font-weight: unset;
        margin: 0;
        text-align: center;
    }
    section#superplanner h1 span { color: var(--primaryColor) }
    section#superplanner h2 {
        align-items: center;
        border-bottom: 1px solid var(--lightGreyColor);
        color: var(--darkGreyColor);
        display: flex;
        font-family: var(--secundaryFont);
        font-size: 18px;
        font-weight: unset;
        margin: 0;
        min-width: 100%;
        padding: 25px;
        padding-bottom: 20px;
    }
    section#superplanner h2 span {
        color: var(--primaryColor);
        font-family: var(--primaryFont);
        font-size: 40px;
        font-weight: unset;
        margin-right: 20px;;
    }
    section#superplanner h3 {
        font-family: var(--secundaryFont);
        font-size: 15px;
        height: 20px;
        margin: 20px 0;
        min-width: 100%;
        position: relative;
    }
    section#superplanner h3 span  {
        background-color: white;
        padding-right: 10px;
        position: relative;
        z-index: 1;
    }
    section#superplanner h3::after {
        background-color: rgba(0,0,0,.1);
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        top: 10px;
        z-index: 0;
    }
    section#superplanner h4 { font-size: 15px; margin: 10px 0 50px 0; text-align: center }
    section#superplanner .infoBox {
        background-color: lightblue;
        border-radius: 5px;
        box-shadow: inset 0 0 2px rgba(0,0,0,.5);
        color: rgba(0,0,0,.75);
        font-size: 12px;
        padding: 10px 15px;
    }
    section#superplanner .button {
        border: 1px solid var(--primaryColor);
        box-shadow: 3px 3px 0px rgba(0,0,0,.1);
        border-radius: 5px;
        color:  var(--primaryColor);
        cursor: pointer;
        font-weight: bold;
        margin: 5px 5px 25px 5px;
        padding: 10px 15px;
        transition: .5s;
    }
    section#superplanner .button:hover { transform: scale(1.05); }
    section#superplanner .button.disabled { cursor: default; display: none; opacity: .5; }
    section#superplanner .button.disabled:hover { transform: unset; }

    section#superplanner .formMessage {
        opacity: 0;
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--error);
        box-shadow: 0 0 5px rgba(0,0,0,.1);
        border-radius: 5px;
        padding: 5px 15px;
        color: white;
        transition: 1s;
    }
    #jobAppForm #view .partWrapper .error-message,
    #login .error-message {
        color: var(--error);
        display: block;
        font-size: 12px;
        font-weight: bold;
        margin-top: 5px;
        min-width: 100%;
        text-align: left !important;
    }
    #jobAppForm #view .partWrapper .input-item { margin: 0 10px 10px 0; }

/* Input fields */

section#superplanner input {
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 7px 10px;
        width: 100%;
    }
    section#superplanner .input-30 { width: calc(33% - 7.9px); }
    section#superplanner .input-50 { width: calc(50% - 10px); }
    section#superplanner .input-75 { width: calc(75% - 10px); }
    section#superplanner .input-100 { width: calc(100% - 10px); }
    section#superplanner select:hover { background-color: rgba(0,0,0,.05); cursor: pointer; }
    section#superplanner select.input-30 { width: 30%; }
    section#superplanner input:invalid,
    section#superplanner select:invalid { box-shadow: none; }
    section#superplanner .error input,
    section#superplanner .error select { border: 1px solid var(--error) !important; }
    section#superplanner button#ok {
        background-color: var(--primaryColor);
        border: none;
        border-radius: 5px;
        box-shadow: 03px 3px 0px rgba(0,0,0,.1);
        color: white;
        cursor: default;
        font-family: var(--secundaryFontBold);
        margin: 0 auto;
        opacity: .5;
        padding: 10px 20px;
    }
    section#superplanner button#ok.active { 
        cursor: pointer; 
        transform: translateX(-25px);
        opacity: 1;
    }

    /* Radio button group */

    .radioGroup { display: flex; flex-flow: column nowrap; margin-bottom: 20px; min-width: 100% }
    .radioGroup label {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;
        font-size: 13px;
        margin-left: 10px;
    }
    .radioGroup input { display: none }
    .radioGroup .radio {
        border: 1px solid darkgray;
        border-radius: 100%;
        height: 20px;
        min-height: 20px;
        min-width: 20px;
        margin: 5px 10px 5px 5px;
        padding: 3px;
        width: 20px;
    }
    .radioGroup .radio .radioFill {
        background-color: var(--primaryColor);
        border-radius: 100%;
        height: 100%;
        transform: scale(0);
        transform-origin: center;
        transition: .5s;
        width: 100%;
    }
    .radioGroup input:checked + .radio .radioFill { transform: scale(1) }

    /* Checkbox group */

    .checkboxGroup { display: flex; flex-flow: column nowrap; margin-bottom: 20px; min-width: 100% }
    .checkboxGroup label {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 13px;
        flex-flow: row nowrap;
        margin-bottom: 10px;
    }
    .checkboxGroup input { display: none }
    .checkboxGroup .checkbox {
        border: 1px solid darkgray;
        border-radius: 5px;
        height: 20px;
        margin: 5px 10px 5px 5px;
        min-height: 20px;
        min-width: 20px;
        padding: 3px;
        width: 20px;
    }
    .checkboxGroup .checkbox .checkboxFill {
        background-color: var(--primaryColor);
        border-radius: 3px;
        height: 100%;
        transform: scale(0);
        transform-origin: center;
        transition: .5s;
        width: 100%;
    }
    .checkboxGroup input:checked + .checkbox .checkboxFill { transform: scale(1) }

    /* Date group */

    .dateGroup { display: flex; margin-bottom: 10px; min-width: 100%; }
    .dateGroup:first-child { margin-left: 0px; }
    .dateGroup select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url('../images/arrow-fill-down.svg');
        background-position: calc(100% - 7px);
        background-repeat: no-repeat;
        background-size: 7px;
        border: 1px solid lightgray;
        height: 37px;
        padding: 5px 25px 5px 15px;
        width: max-content;
    }
    .dateGroup select:first-child { border-radius: 5px 0 0 5px; border-right: none }
    .dateGroup select:last-child { border-left: none; border-radius: 0 5px 5px 0 }
    #jobAppForm #view .partWrapper .dateGroup span { margin: 0 10px }
    #jobAppForm #view .partWrapper .WieKenJe span { display: block; font-size: 13px; margin: 10px 0; }

/* Container */

section#superplanner #container {
        background-color: #f9f9f9;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        /* min-height: 100vh; */
        min-height: calc(100vh - 57px);
    }

/* Job application form */

    #jobAppForm {
        border-radius: 5px;
        display: flex;
        flex-flow: column nowrap;
        margin: 25px;
        max-width: 750px;
    }
    #jobAppForm .jobAppFormView { 
        display: flex;
        flex-flow: row nowrap;
        position: relative;
    }
    #jobAppForm  #view {
        display: flex;
        flex-flow: row nowrap;
        max-width: 750px;
        position: relative;
        transition: .5s;
        width: 100%;
    }
    #jobAppForm #view .part {
        min-width: 750px;
    }

    /* Process */
    
    #jobAppForm #process {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 25px rgba(0,0,0,.1);
        display: flex;
        flex-flow: column nowrap;
        margin-right: 20px;
        max-height: 224px;
        min-width: max-content;
        overflow: auto;
        position: sticky;
        top: 20px;
    }
    #jobAppForm #process .processItem {
        align-items: center;
        color: var(--darkGreyColor);
        display: flex;
        padding: 10px 20px;
        text-decoration: none;
    }
    #jobAppForm #process .processItem:hover { background-color: rgba(0,0,0,.05) }
    #jobAppForm #process .processItem span {
        color: var(--primaryColor);
        font-family: var(--primaryFont);
        font-size: 30px;
        margin-right: 10px;
    }
    .labelTitle {
        margin-left: 5px !important;
        font-size: 13px !important;
    }

    /* View */

    #jobAppForm form { 
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 25px rgba(0,0,0,.1);
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;
    }
    #jobAppForm #view .partWrapper { display: flex; flex-flow: row wrap; padding: 25px 25px 25px 80px }
    #jobAppForm #view .partWrapper span { font-size: 15px; }
    #jobAppForm #view .vacationDate,
    #jobAppForm #view .vacationDateExtra,
    #jobAppForm #view .vacationDateExtra2 { align-items: center; display: flex; flex-flow: row wrap }
    #jobAppForm #view .vacationDate .dateGroup,
    #jobAppForm #view .vacationDateExtra .dateGroup,
    #jobAppForm #view .vacationDateExtra2 .dateGroup { min-width: max-content }
    #jobAppForm #view .vacationDateExtra2 { min-width: 100%; }
    #jobAppForm #view .partWrapper .sub_title { font-size: 12px; margin-bottom: 10px; }
    #jobAppForm .viewTabs {
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
    }
    #jobAppForm .viewTabs .viewTab {
        flex: 1;
        font-size: 13px;
        padding: 10px 15px;
        text-align: center;
    }
    #jobAppForm .viewTabs .viewTab.active {
        color: var(--primaryColor);
    }
    .choiceInternJob {
        margin-top: 10px;
        width: 100%;
    }
    .periodeKnop {
        display: inline-block;
        border: 1px solid rgba(0,0,0,.25);
        cursor: pointer;
        padding: 10px 15px;
        border-radius: 5px;
        font-size: 12px;
        margin: 15px 0 5px 0;
        color: rgba(0,0,0,.75);
        position: relative;
        transition: .5s;
    }
    .periodeKnop:hover {
        transform: scale(1.05);
    }
    #jobAppForm #view .vacationDate span,
    #jobAppForm #view .vacationDateExtra span,
    #jobAppForm #view .vacationDateExtra2 span {
        display: block;
        font-size: 13px;
        margin: 0 35px;
    }

/* Media queries */
    @media only screen and (max-width: 767px) {
        section#superplanner .input-30 {
            width: calc(50% - 10px);
        }
        #jobAppForm #view .partWrapper {
            padding: 20px;
        }
    }
    @media only screen and (max-width: 800px) {
        #jobAppForm { max-width: 500px; }
        #jobAppForm .jobAppFormview #view .part { min-width: 500px; }
    }
    @media only screen and (max-width: 550px) {
        #jobAppForm .viewTabs .viewTab { display: none;}
        #jobAppForm .viewTabs .viewTab.active { display: block;}
        #jobAppForm { max-width: 400px; padding: 10px; }
        #jobAppForm .jobAppFormview #view .part { min-width: 400px; }
    }
    @media only screen and (max-width: 550px) {
        section#superplanner input.input-30,
        section#superplanner input.input-50,
        section#superplanner input.input-75 { width: 100% }
        section#superplanner button { transform: none; width: 100% }
        #jobAppForm #view .partWrapper { padding: 15px }
    }

    .jobAppFormView .content {
        height: auto !important;
    }